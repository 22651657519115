import { Helmet } from 'react-helmet'

import * as React from 'react'
import Layout from '../components/Layout'

import { Link, graphql } from 'gatsby'
import Img from 'gatsby-image'
import FAQs from '../components/FAQs'

import { Box, Flex } from 'grid-styled'
import {
  HomeHeader,
  FormContainer,
  AlternatingContent,
  AboutContact,
  VideoContainer,
  info,
} from '.'
import Form from '../components/Form'
import {
  Text,
  GallerySection,
  Content,
  MainContent,
} from '../components/styles'
import Button from '../components/Button'
import ContentCard from '../components/ContentCard'
import Hero from '../components/Hero'
import { Video } from '../components/procedures'
import GatsbyImage from 'gatsby-image'
import GatsbyLink from 'gatsby-link'
import CTA from '../components/CTA'

class Comp extends React.Component {
  state = {
    active: 'juniorDoctors',
  }
  makeVisible = (id: string) => {
    const section = document.getElementById(id)
    const others = document.getElementsByClassName('article-stages')
    if (section !== null) {
      Array.from(others).map((el) => (el.style.display = 'none'))
      section.style.display = 'block'
      this.setState({ active: id })
    }
  }
  render() {
    return (
      <Layout header="light">
        <Helmet>
          <title>Personal Advice - Parinity Financial Professionals</title>
          <meta
            name="description"
            content="We provide transparent financial advice for individuals and families. Parinity offers a comprehensive approach, accommodating the full
            spectrum of clients’ financial needs."
          />
        </Helmet>

        <HomeHeader slant={false} height="75vh">
          <figure>
            <Img fluid={this.props.data.jpBackground.childImageSharp.fluid} />
          </figure>
          <div>
            <h2>personal advice</h2>
            <Text size="large">
              Parinity offers a comprehensive approach, accommodating the full
              spectrum of clients’ financial needs.
            </Text>
            <Link to="/contact">
              <Button outline={true} color="#fff">
                Contact us
              </Button>
            </Link>
          </div>
          <FormContainer>
            <div>
              <Form title="Contact us today" showMessage={true} />
            </div>
          </FormContainer>
        </HomeHeader>

        <Content style={{ paddingTop: 100 }}>
          <h1>parinity - personal financial advice</h1>

          <span>
            Parinity offers a comprehensive approach, accommodating the full
            spectrum of clients’ financial needs.
          </span>

          <p>
            We provide transparent financial advice that is unique to you and
            varies based on complexity. We understand that some clients want an
            active role in the management of their finances while others just
            want the piece of mind to know their finances are structured to meet
            their goals, both now and in the future. We aim to give clients
            financial confidence and the opportunity to focus on other important
            aspects of their lives such as family, careers and leisure pursuits.
          </p>

          <h4>our approach</h4>

          <p>
            Our approach is engaging and all encompassing. We explore all
            aspects of your financial life to understand what truly motivates
            you and your financial decisions. Our priority is to recommend the
            best financial solution for you and then work with you to make it
            happen.
          </p>

          <h4>our offering</h4>
          <ul>
            <li>Showing you where your money goes and how to maximise it</li>
            <li>Getting rid of bad debt and managing good debt</li>
            <li>Finding the best loan structure for you</li>
            <li>
              A step by step approach to purchasing property – guiding you
              through from start to finish
            </li>
            <li>Providing a clearer understanding to investing in shares</li>
            <li>
              Taking control of your superannuation to have more certainty in
              retirement
            </li>
            <li>Protecting the things that matter most to you</li>
            <li>
              Using innovative technology to streamline your financial affairs
            </li>
          </ul>

          <p>
            We also have an extensive range of trusted partners who can support
            specialist activities that compliment your financial needs such as:
          </p>

          <Box width="500px" mb={4} style={{ margin: '0 auto' }}>
            <Img fluid={this.props.data.graphic.childImageSharp.fluid} />
          </Box>

          <p>
            To get more information on how we can work with you,{' '}
            <GatsbyLink to="/pathways">
              please click here to view our Parinity Pathways
            </GatsbyLink>
          </p>

          <Button
            onClick={(e) => {
              e.preventDefault()
              Calendly.showPopupWidget('https://calendly.com/lancecheung/')
            }}
            inverted={true}
          >
            Book an appointment now!
          </Button>
        </Content>

        <Hero
          bgImage={this.props.data.blurredHero.childImageSharp}
          height="550px"
        >
          <h3>Want to find out more?</h3>
          <p>
            Call 07 3114 2500 or complete the enquiry form to contact us today
            and find out how we can help.
          </p>
          <Flex>
            <Button
              onClick={(e) => {
                e.preventDefault()
                Calendly.showPopupWidget('https://calendly.com/lancecheung/')
              }}
              color={'#fff'}
              small={true}
              outline={true}
            >
              Book an appointment online
            </Button>
          </Flex>
        </Hero>

        <AboutContact />

        <CTA
          title="Download our wealth tracking app"
          subtitle="A better tracking solution."
          button="Access Parinity Wealthdesk"
          inverted={true}
          to="https://parinity.wealthdesk.com.au/my/home"
        />
      </Layout>
    )
  }
}
export default Comp

export const query = graphql`
  query PersonalQuery {
    jpBackground: file(relativePath: { eq: "personal-new.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 3000, quality: 95) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    junior: file(relativePath: { eq: "junior.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    specialists: file(relativePath: { eq: "specialists.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    registrars: file(relativePath: { eq: "registrars.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    whyUs: file(relativePath: { eq: "why-us-jp.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 3000, quality: 95) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    accessories: file(relativePath: { eq: "roofing-brisbane.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2000, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    blurredHero: file(relativePath: { eq: "cta-bg.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    graphic: file(relativePath: { eq: "personal-graphic.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    gallery: allFile(filter: { relativeDirectory: { eq: "gallery/patios" } }) {
      edges {
        node {
          childImageSharp {
            fixed(width: 400, height: 400, toFormat: JPG) {
              ...GatsbyImageSharpFixed
            }
            modal: fluid(maxWidth: 1200, quality: 98, toFormat: JPG) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
